$modalBackgroundColor: #fff;

$inputColor: #1C2677;
$inputBackgroundColor: #F4F4F4;

$violetBlueColor: #2B5A64;

$buttonColor: #fff;
$buttonBackgroundColor: linear-gradient(270deg, #944AF8 -5.04%, #A652BE 114.63%);
$buttonModalBackgroundColor: linear-gradient(270deg, #944AF8 -5.04%, #A652BE 114.63%);
$buttonBackgroundColorHover: #fff;
$buttonBackgroundColorDisabled: linear-gradient(0deg, #19191926 0%, #19191926 100%); ;
$buttonTextColorDisabled: linear-gradient(0deg, #333333 0%, #333333 100%);

$profileButtonColor: linear-gradient(90.44deg, #47605C 0%, #47605C 100%);
$profileButtonActiveColor: #844ECE;
$mainFont: "Open Sans", sans-serif;
$boxShadowGrey: rgba(197, 196, 208, 0.64);
$boxShadowPurple: rgba(84, 0, 114, 0.24);

$iconCrossSearchColor: #844ECE;
$iconResetPasswordBackgroundColor: #ffe2cc;
$iconResetPasswordFillColor: #d45c00;
$iconCoinsBalanceGradientColor: linear-gradient(270deg, #944AF8 -5.04%, #A652BE 114.63%);
$iconCoinsBalanceDefaultColor: #844ECE;
$iconLikeWomanProfileFillColor: #EB5757;
$iconAddPlusFillColor: #844ECE;
$iconAddPhotoFillColor: #844ECE;
$iconVideosFillColor: #844ECE;
$iconHandWaveFillColor: #844ECE;
$iconLetterFillColor: #844ECE;
$iconFilterFillColor: #844ECE;
$iconLikeVideoComponentFillColor: #844ECE;
$iconLetterVideoComponentFillColor: #844ECE;
$iconMoveFillColor: #844ECE;
$iconUploadAttachmentFillColor: #844ECE;
$iconArrowLeftFillColor: #844ECE;
$iconsMoreInfoChatHeaderFillColor: #844ECE;
$iconProfileFillColor: #844ECE;
$iconCrossCloseFillColor: #844ECE;
$iconReplyFillColor: #844ECE;

$supportFeedbackModalHeaderBgColor: rgba(148, 74, 248, 0.1);
$supportFeedbackRadioLabelColor: #844ECE;
$supportFeedbackRadioLabelColorChecked: #844ECE;
$supportFeedbackRadioLabelColorHover: #844ECE4D;
$supportFeedbackRatingTextColor: #844ECE;
$supportFeedbackTitleColor: #A652BE;
$supportFeedbackTextColor: #333333;
$supportFeedbackButtonBorderHover: double 1px transparent;

$greenColor: #1C9D53;
$whiteColor: #fff;
$greyColor: #4F4F4F;
$greyBlueColor: #426076;

$inputTextColor: #844ECE;

$inputBorderNormal: #844ECE;
$inputBorderHover: #A652BE;
$inputBorderActive: #A652BE;

$selectBackgroundColor: #f4ecff;
$selectBorderColor: #844ECE;
$selectBorderHoverColor: #844ECE;

$textareaBackgroundColor: #FFFFFF;
$textareaBackgroundHoverColor: #FFFFFF;
$textareaBackgroundActiveColor: #FFFFFF;
$textareaBorderColor: #834FCE;
$textareaBorderActiveColor: #A652BE;
$textareaOutlineColor: #A652BE1a;

$messageRightBackground: #F4F4F4;
$messageLeftBackground: #F8EFFF;

$chatTextareaBg: #F4F4F4;

$attachTouchText: #844ECE;

$bluePinkColor: #844ECE;

$uploadTabsColor: #334B48;
$uploadTabsActiveColor: #844ECE;

$uploadTabsItemBg: #E7EEF4;

$uploadTabsButton: #844ECE;

$mediaListTabActiveBg: #F8EFEB;
$mediaListTabActiveColor: #333;

$mediaListSwitcherActiveBg: #F8EFEB;

$searchBottomBtn: #844ECE;

$buttonHoverBg: #954bf54f;
$buttonHoverBg2: #F8EFEB;
$buttonHoverBg3: #f4ecff;

$lettersMenuIconColor: #47605C;

$videoButtonsColor: #844ECE;

$paymentBorderColor: rgba(68, 96, 119, 0.3);
$paymentBorderActiveColor: #798F8C;

$unreadLetter: #E8F1FA;

//LOGIN MODAL
$colorLoginForgetButton: #EB5757;
$colorLoginBottom: rgba(0, 0, 0, .2);
$colorLoginBottomSpan: #474F5C;

//ERROR MODAL
$colorErrorTitle: #00317B;
$colorErrorTitleHref: #1952ab;

//MAIN PAGE
$buttonArrowBackground: rgba(240, 249, 255, 0.9);

//ICONS

$c1ProfileIco: url(../../img/c1-profile_btn.svg);

$c3StickerViolet: url(../../img/c3-msg-sticker-violet.svg);
$c3StickerBlue: url(../../img/c3-msg-sticker-blue.svg);

$chatHello: url(../../img/chat-hello.svg);

$profileEdit: url(../../img/c3ph-edit.svg);
$profileEditH: url(../../img/c3ph-edit-h.svg);

$profileCredits: url(../../img/c3ph-purchase.svg);
$profileCreditsH: url(../../img/c3ph-purchase-h.svg);

$profileGifts: url(../../img/c3ph-gift.svg);
$profileGiftsH: url(../../img/c3ph-gift-h.svg);

$profileContact: url(../../img/c3ph-contact.svg);
$profileContactH: url(../../img/c3ph-contact-h.svg);

$profileMeeting: url(../../img/c3ph-meet.svg);
$profileMeetingH: url(../../img/c3ph-meet-h.svg);

$profileBlock: url(../../img/block-list-p.svg);
$profileBlockH: url(../../img/block-list-p-h.svg);

$profileLogout: url(../../img/logout-p.svg);

$editProfileBtn: url(../../img/edit-profile-btn.svg);

$profileLetter: url(../img/c3wpt_mail.svg);
$profileFavs: url(../img/c3wpt_favs.svg);
$profileComplain: url(../img/c3wpt_compl.svg);
$profileGiftTop: url(../img/c3wpt_gift.svg);

$messageStatusRead: url(../../img/readed.svg);
$messageStatusSend: url(../../img/send.svg);

$paymentSelectedCheck: url(../../../../img/check-circle-full-violet.svg);

$npsRadioLabelColor: #944AF8;
$npsRadioLabelColorChecked: #944AF8;
$npsRadioLabelColorHover: #844ECE4D;
$npsButtonBorderHover: double 1px transparent;
$ratingTextColor: #333333;
$npsInputBorderColor: #834FCE;
$npsCheckboxColor: #9C6EF7;
$npsContactsInputColor: #333333;
$npsTextAreaBackgroundActiveColor: #F5F7FA;
$npsModalTitleColor: #944AF8;

//POLICY
$policyTitleColor: #A652BE;
$policyTextColor: #844ECE;
$policyButtonBg: #FCF2FF;
$policyLinksColor: #0000FF;
$policyLinksHoverColor: #0101D6;
$policyScrollColor: #BB9AE9;

@import 'theme';
