// COLORS
// ACCENT
// gradient colors
$accentLeftColor: #944AF8;
$accentRightColor: #A652BE;

$accentLeftRightColor: #A652BE;
$bleachColor: #f4f4f4;

// PRIMARY
$primaryColor: #844ECE;
$primary2Color: #798F8C;
$primaryLiteColor: #3f7298; // checkbox, player elements (play, progress, etc)
$primaryOrangeColor: #844ECE;
$primarySearchButton: #fff;
$primarySearchButtonBorder: linear-gradient(90.5deg, $accentLeftColor 0%, $accentRightColor 100%);
$primarySearchButton2: $primaryColor;
$primarySearchButtonBorder2: $primaryColor;
$primarySearchButtonBackground2: #fff;
$primarySearchButton3: #5C4D70;
$primarySearchButton3BackgroundHover: #fff;

// BACKGROUND
$background1Color: #F7F1FF; // column 1, 2, 4 and other shit
$background2Color: #F8EFEB; // inbox messages
$background3Color: #FDE5DA; //?= active item background
$background4Color: #D1E2EE; // outcome messages
$background5Color: #F4ECFF; //= active input background color
$background6Color: #F8EFEB;
$backgroundOnlineColor: linear-gradient(90deg, #1C9D53 1.42%, rgba(28, 157, 83, 0.7) 99.86%);
$backgroundSearchItemShadowColor: rgba(197, 196, 208, 0.64);
$backgroundButtonColor: linear-gradient(270deg, #944AF8 0%, #A652BE 100%);
$backgroundTopMenuButtonColor: #fff;
$background2TopMenuButtonColor: #844ECE;
$backgroundSearchColor: linear-gradient(0deg, #FFF 48.74%, rgba(255, 255, 255, 0.81) 59.89%, rgba(255, 255, 255, 0.00) 99.34%);

//LikeButton
$defaultLikeButtonColor: #844ECE;
$activeLikeButtonColor: #5C4D70;

//MENU
$policyHoverColor: #FFF;
$addCreditsBackgroundColor: #FFF;
$addCreditsHoverColor: $accentRightColor;
$submenuButtonColor: rgba(55, 78, 108, 0.7);
$backgroundButtonActiveColor: $primaryColor;
$buttonTitleActiveColor: #FFF;
$backgroundButtonHoverColor: #F4ECFF;
$buttonTitleHoverColor: $primaryColor;
$buttonTitleColor: #5C4D70;
$smallActiveButtonColor: $primaryColor;
$newBadgeBg: #A351C9;
$newBadgeColor: #FFF;
$newBadgeHoverBg: #A351C9;
$newBadgeHoverColor: #FFF;
$newBadgeActiveBg: #FFF;
$newBadgeActiveColor: #A351C9;
$buttonBorderPolicyColor: $primaryColor;
$buttonTitlePolicyMenu: $primaryColor;

// OTHERS
// mutable
$iconColor: #944AF8;
$counterAndErrorColor: #E7643D; //= counter ёпта
$scrollbarThumbColor: #A8B7B5; // scrollbar thumb
$menuIconColor: #3664a1;
$onlineTitleColor: #fff;
$buttonBorderColor: #844ECE;
$buttonBorder2Color: #844ECE;
$buttonTitleTopMenu: #844ECE;
$buttonTitle2TopMenu: #fff;

// SEARCH
$searchFilterTitle: #5C4D70;
$searchFilterSelectBg: $selectBackgroundColor;
$searchFilterSelectBorder: $selectBorderColor;
$searchFilterSelectColor: #333;
$searchBoxShadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
$searchBoxShadowBorder: rgba(10, 186, 181, 0);
$searchPlaceholder: #844ECE;
$searchSelectBg: #f4ecff;
$searchItemBtnBg: $backgroundButtonColor;
$searchItemBtnShadowHover: 0px 6px 12px rgba(149, 74, 243, 0.24);
$searchItemLikeBtnShadowHover: 0px 6px 12px rgba(149, 74, 243, 0.24);
$smallBtnShadowHover: 0 6px 12px rgba(149, 74, 243, 0.24);
$searchSayHelloBtnBorder: #446077;
$searchSayHelloBtnColor: #446077;
$searchProfileNameColor: #333333;

// Header Main
$MainHeaderBg: #F7F1FF;
$MainStartBlockShadow: linear-gradient(0deg, #F7F0FF 13.85%, rgba(247, 240, 255, 0.78) 48.23%, rgba(247, 240, 255, 0) 100%);
$mainTitleColor: #944AF8;
$mainTextColor: #5C4D70;
$mainStartBlockTextColor: #5C4D70;
$FooterBg: linear-gradient(270deg, #944AF8 -5.04%, #A652BE 114.63%);
$mainButtonBg: $backgroundButtonColor;
$ButtonTransitional: unset;
$mainButtonHoverBg: #944AF8;

// Main Form
$InputSelectedTextColor: #5C4D70;

// NOTIFY
$notificationScrollbarBg: #F7F8FD;
$notificationMessageBg: #A351C9;
$notificationMessageText: #FFF;
$notificationFavouriteBg:#844ECE;
$notificationFavouriteText: #FFF;
$notificationLikedBg: #FFF;
$notificationLikedTitle: #474F5C;
$notificationLetterBg: $primaryColor;
$notificationDefaultText:  #333;
$notificationOfflineColor: #E0E0E0;
$notificationFavouriteIcon: #f2c94c;
$notificationMatchBg:#D4BCF6;
$notificationMatchColor: #333;
$notifyItemSayHiButtonBackground: #FFF;
$notifyListShadow: linear-gradient(180deg, rgba(235, 237, 250, 0) 0%, #EBEDFA 100%);
$notifyListBottomBtn: #D5DAF3;
$notifyBigPhotoBg: #A351C9;
$notifyBigPhotoFavBg: #844ECE;
$notifyCounterBg: #EB5757;
$notifyCounterColor: #fff;
$notifyMainShadow: -6px 0px 12px rgba(47, 43, 99, 0);
$notifyItemClose: #33333399;
$notifyItemEachLikeHeart: #CC193B;
$notifyListBorder: #D5DAF3;
$notifyBlockBorder: #fff;
$notifyGirlItemBg: #fff;
$notifyGirlItemTitle: #474F5C;
$notifyGirlItemAppealText: #4336de;
$notifyGirlItemNoChatText: #4336de;
$notifyGirlItemNoChatMobText: #9B51E0;
$notifyGirlItemWantChatText: #00317B;
$notifyGirlItemOnlineText: #1C9D53;
$notifyGirlItemButtonText: #fff;
$notifyGirlItemButtonHiText: #001027;
$notifyGirlItemMatchDetails: #33333399;
$notifyMobDefaultBg: #fff;
$notifyMobHoverBg: #F1F1F1;
$notifyMatchChain: #F37070;
$notifyMobBottomBg: $background1Color;
$notifyItemEachLike: #D4BCF6;
$notifyItemEachLikeOnlineBorder: #D4BCF6;
$notifyItemEachLikeTitleColor: #333;
$notifyItemEachLikeTitle2Color: #333;
$supportTextColor: $accentRightColor;

// CHAT
$chatItemActiveBg: $primaryColor;
$chatItemActiveTitleColor: #FFFFFF;
$chatItemFromTitleColor: #6F0267;
$chatItemFromActiveTitleColor: #fff;
$chatHeaderOnlineBorder: #446077;
$chatItemBtnColor: #844ECE;
$chatNewBg: #EB5757;
$chatItemTitleColor: #474F5C;

// LIKE
$likeOnlineBg: linear-gradient(90deg, rgba(28, 157, 83, 0.7) 1.42%, #1C9D53 99.86%);
$likeOnlineMobBg: $likeOnlineBg;
$likeOnlineColor: #fff;
$likeSliderOnlineBg: linear-gradient(270.09deg, #27AE60 0.08%, rgba(39, 174, 96, 0.7) 99.93%);
$likeSliderOnlineColor: #fff;

// LETTER
$letterReadBg: green;

// PROFILE
$profileTitleColor: #446077;
$profileBorderSelect: $accentLeftColor;

// Mobile chat
$mobileChatButtonNotificationSelectedBackground: #ebedfa;
$mobileChatEmptyTitleColor: #426076;
$mobileChatEmptyTextColor: #333333cc;
$mobileChatIconColor: #446077;
$mobileChatProfileNameColor: #474F5C;
$mobileChatMenuLinkColor: #00317B;
$mobileChatMenuBoxShadow: #0e048f3d;
$mobileChatMenuButtonBackground: #F7F7FE;
$mobileChatMessageBackgroundPrimary: #B6D1FE;
$mobileChatMessageBackgroundSecondary: #EEF1FE;
$mobileChatMessageTextColor: #333333;
$mobileChatMessageDateColor: #BDBDBD;
$mobileChatMessageGreetingBackground: #E8EEF8;
$mobileChatMessageGreetingColor: #5C6C8A;
$mobileChatFormMessageButtonBackground: linear-gradient(90.13deg, #00317B 0%, #720066 100%);
$mobileChatFormMessageFileUploadIconColor: #909FB8;
$mobileChatFormMessageTextareaBorderColor: #D6DFEE;
$mobileChatFormMessageTextColor: #4F4F4F;


// Letter chat
$mobileLetterMessageBookmarkedBackground: #d6daff;
$mobileLetterMessageBackgroundPrimary: #DBE5F7;
$mobileLetterMessageBackgroundSecondary: #F3F4FC;
$mobileAudioButtonBackground: #9C6EF7;
$mobileAudioNameTextColor: #4C3083;
$mobileAudioProgressBackgroundDefault: #9c6ef780;
$mobileAudioTimeColor: #33333399;


// Unsubscribe Page
$unsubscribeTitleColor: #2B5A64;
$unsubscribeTextColor: #4F4F4F;
$unsubscribeInputBackground: #F8EFEB;
$unsubscribeInputPlaceholderColor: #2B5A6480;

// Modal Welcome
$modalWelcomeBackground: rgba(68, 96, 119, 0.3);
$modalWelcomeTitleColor: #944AF8;
$modalWelcomeTextColor: #4F4F4F;
$modalWelcomeTipsBackground: rgba(164, 36, 89, 0.05);
$modalWelcomeTipsColor: #B42C54;

$stepTitle: #00317B;
$stepNameColor: #01327C;
$stepNameSpanColor: $accentRightColor;
$stepDescriptionColor: rgba(51, 51, 51, 0.8);
$stepDescriptionSpanColor: #333;
$stepSkipButtonColor: #446077;
$stepNavigationDefault: rgba(28, 38, 119, 0.8);
$stepNavigationActive: #1c2677;
$stepNavigationPassed: rgba(28, 38, 119, 0.8);
$stepNextButtonColor: #446077;
$stepNextButtonHoverShadow: 0 4px 8px rgba(14, 4, 143, 0.24);
$stepMobButtonTextColor: $accentLeftColor;
$stepTutorialLeftMenuColor: #446077;
$stepHelloButtonBackground: $backgroundButtonColor;
$stepHelloButtonColor: #FFFFFF;
$stepHelloButtonBorder: 0;
$stepLikeButtonBorder: 1px solid #446077;
$stepLikeButtonColor: #446077;
$step6background: #EBEDFA;
$step6NotifyTitleColor: $uploadTabsActiveColor;


//How-to-start
$tutorialPopupBackground: #FFFFFF;
$tutorialPrimaryBackground: #EBEDFA;
$tutorialSecondaryBackground: #F4F4F4;
$tutorialButtonLightBackground: #FFFFFF;
$tutorialPrimaryIllustrationBackground: rgba(27, 42, 65, 0.20);
$tutorialMorePhotoBackground: rgba(255, 255, 255, .8);
$tutorialFilterItemBackground: $background5Color;
$tutorialMatchBoxBackground: $notificationMatchBg;
$tutorialMatchBoxTitleColor: $notificationMatchColor;
$tutorialMatchBoxTimeColor: rgba(51, 51, 51, 0.80);
$tutorialHelloBoxBackground: $background1Color;
$tutorialNotifySidebarBackground: $background1Color;
$tutorialNotifySidebarTextColor: $primaryColor;
$tutorialButtonLightColor: #FFFFFF;
$tutorialButtonGreyColor: #6F6F6F;
$tutorialCloseButtonGreyColor: #C2C2C2;
$tutorialPaginationItemColor: #F1F1F1;
$tutorialStepSecondaryColor: $primaryColor;
$tutorialCategoriesColor: rgba(55, 78, 108, 0.56);
$tutorialUserIdColor: rgba(68, 96, 119, 0.40);
$tutorialPrimaryGreenColor: #27AE60;
$tutorialChatProfileNameColor: #474F5C;
$tutorialChatMsgColor: #4F4F4F;
$tutorialChatMsgColor: #BDBDBD;
$tutorialFilterItemBorder: $primaryColor;
$tutorialFilterOnlineColor: #374e6c;
$tutorialMatchBoxUserColor: #A0A0A0;
$tutorialStepTextColor: rgba(51, 51, 51, 0.80);
$tutorialLightTextColor: #FFFFFF;
$tutorialStepTextSecondaryColor: #333333;
$tutorialSidebarDividerColor: $notifyListBottomBtn;
$tutorialHeartColor: #CC193B;
$accentLightColor: $accentRightColor;
$accentDarkColor: $accentLeftColor;
$tutorialMenuItemColor: #5C4D70;
$tutorialPrimaryBorderColor: #DFDFDF;
$tutorialSecondaryBorderColor: #BBBFDD;
$tutorialHeaderBorderColor: #DDDDDD;
$tutorialButtonBorderColor: #CDD3DA;
$tutorialDefaultIconColor: #8F9CAD;
$tutorialOnlineTextColor: #FFFFFF;
$tutorialButtonBackground: $backgroundButtonColor;
$tutorialOnlineBackground: $backgroundOnlineColor;
$tutorialLightGradient: linear-gradient(to top, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
$tutorialSliderListGradient: linear-gradient(-90deg, $tutorialSecondaryBackground 0%, rgba(244, 244, 244, 0.00) 100%);
$tutorialImageBackground: linear-gradient(178.98deg, rgba(235, 237, 250, 0) 0%, rgba(235, 237, 250, .63) 40%, rgba(235, 237, 250, .91) 61%, #ebedfa 100%);

//Forbidden page
$forbiddenPageBg: #F7F1FF;
$forbiddenTextColor: #844ECE;
$forbiddenSVGShadowColor: #DDCEF2;
$forbiddenSVGMapColor: #7632B4;
$forbiddenSVGMapBackground: #9E55E0;

//Payment for first credit
$paymentBannerTitle: #733EBC;
$paymentBannerOffer: #9857F1;
$paymentCorsTitle: #333333;
$paymentCorsItemTitle: #333333;
$paymentProsIconColor: #9857F1;
$paymentProsIconBackground: #9857F11A;
$paymentButtonBackground: #733EBC;
$paymentButtonColor: #FFF;
$paymentButtonBackgroundHover: #9857F1;
$paymentButtonOutline: #9857F11A;

@import '../common';
