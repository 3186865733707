@import "@sitestyles";

.main_info_block {
  padding: 75px 0;
  background: $MainHeaderBg;
  @media screen and (max-width: 1140px) {
    padding: 48px 0;
  }

  @media screen and (max-width: 641px) {
    padding: 32px 0;
  }
}

.main_info_block_list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  justify-content: center;
  row-gap: 150px;
  grid-row-gap: 150px;
  @media screen and (max-width: 1400px) {
    row-gap: 120px;
    grid-row-gap: 120px;
  }
  @media screen and (max-width: 1024px) {
    row-gap: 56px;
    grid-row-gap: 56px;
  }
  @media screen and (max-width: 540px) {
    display: grid;
  }
}

.main_info_block_item {
  width: 387px;
  max-width: 387px;
  text-align: center;
  flex: 1 22.5%;
  @media screen and (max-width: 1700px) {
    flex: 1 30%;
  }
  @media screen and (max-width: 1280px) {
    flex: 1 47.5%;
  }
  @media screen and (max-width: 870px) {
    width: 250px;
    max-width: 250px;
  }
  @media screen and (max-width: 670px) {
    flex: 1 100%;

  }
}

.main_info_block_item_ico {
  width: 100px;
  height: 100px;
  object-fit: contain;
  display: block;
  margin: 0 auto 48px;
  @media screen and (max-width: 1100px) {
    margin-bottom: 24px;
    width: 96px;
    height: 96px;
  }
}

.main_info_block_item_title {
  font-weight: 600;
  font-size: 24px;
  color: $mainTitleColor;
  line-height: 32px;
  width: 65%;
  margin: 0 auto 8px;

  @media screen and (max-width: 1140px) {
    width: 100%;
  }

  @media screen and (max-width: 641px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.main_info_block_item_text {
  font-size: 16px;
  line-height: 22px;
  color: $mainTextColor;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 870px) {
    width: 100%;
  }
  @media screen and (max-width: 641px) {
    font-size: 14px;
    line-height: 19px;
  }
}